import React, { useState, useEffect } from "react"

import Typography from "@material-ui/core/Typography"

import Layout from "../components/layout/layout"

import YellowHeader from "../components/general/yellowHeader"

import useStyles from "./textPages.style"
import SEO from "../components/seo"

export default function Presse() {
  const classes = useStyles()

  return (
    <Layout>
      <SEO />
      <YellowHeader title="Conditions générales d’utilisation" icon="📜" />

      <div className={classes.wrapper}>
        <Typography
          className={classes.sectionTitle}
          variant="h5"
          color="initial"
        >
          Introduction
        </Typography>

        <Typography
          className={classes.paragraph}
          variant="button"
          color="initial"
        >
          Inshallah prend très au sérieux les sujets liés au respect de la vie
          privée et s’engage à protéger la confidentialité de ses Membres. A ce
          titre, nous mettons à votre disposition la présente charte
          d’utilisation des cookies, ceci afin de vous informer sur ce que sont
          les cookies, les différents types de cookies que nous utilisons sur le
          Site, la façon dont nous les traitons et dont vous pouvez les
          configurer.
        </Typography>
        <Typography
          className={classes.sectionTitle}
          variant="h5"
          color="initial"
        >
          Introduction
        </Typography>

        <Typography
          className={classes.paragraph}
          variant="button"
          color="initial"
        >
          Inshallah prend très au sérieux les sujets liés au respect de la vie
          privée et s’engage à protéger la confidentialité de ses Membres. A ce
          titre, nous mettons à votre disposition la présente charte
          d’utilisation des cookies, ceci afin de vous informer sur ce que sont
          les cookies, les différents types de cookies que nous utilisons sur le
          Site, la façon dont nous les traitons et dont vous pouvez les
          configurer.
        </Typography>
      </div>
    </Layout>
  )
}
